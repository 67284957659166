@import "colors";

$action-transition-duration: 0.25s;
$action-transition-timing-function: ease;

/* Font Sizes */
$font-sm: 0.85rem;
$font-regular: 0.9rem;
$font-md: 1rem;
$font-lg: 1.5rem;
$font-xxl: 2rem;
/* End Font Sizes */

/* Sizes */
$xs: 5px;
$sm: 10px;
$md: 15px;
$lg: 20px;
$xl: 25px;
$xxl: 30px;
/* End Sizes */

$font-roboto: 'Roboto';

/* Colors */
$theme-color: $material-color-indigo-900;
$border-color: #ced4da;
$section-inverse-color: #f8faff;
$blue: #376cea;
/* End Colors */

$default-ls : 1px; /*Default Letter Spacing*/

$round-btn-padding-y: 10px;
$round-btn-padding-x: 25px;
$round-btn-radius: $round-btn-padding-x;
