@import "colors";
@import "variables";

.country-link {
  font-size: $font-sm;
  line-height: 2.8;
  letter-spacing: $default-ls;
}

.ui.steps {
  .step {
    .content {
      font-family: $font-roboto;
      .title {
        font-size: $font-regular;
        font-weight: bold;
      }
      .description {
        font-size: $font-sm;
        line-height: 1.2;
      }
    }
  }
}

.square-xs {
  width: 16px !important;
  height: 16px !important;
}
.square-sm {
  width: 32px !important;
  height: 32px !important;
}
.square-md {
  width: 48px !important;
  height: 48px !important;
}
.square-lg {
  width: 64px !important;
  height: 64px !important;
}
.square-xl {
  width: 80px !important;
  height: 80px !important;
}
.square-xxl {
  width: 96px !important;
  height: 96px !important;
}

.device-list {
  font-family: $font-roboto;
  .mdc-list-item__text {
    .mdc-list-item__primary-text {
      font-weight: 500;
      font-size: $font-regular;
      letter-spacing: $default-ls;
    }
    .mdc-list-item__secondary-text {
      font-size: $font-sm;
      letter-spacing: $default-ls;
    }
  }
}

.settings-icon {
  font-size: $font-xxl;
  color: $material-color-grey-400;
}

a.bold-link {
  font-size: $font-regular;
  font-family: $font-roboto;
  letter-spacing: $default-ls;
  text-decoration: none;
  font-weight: 500;
}

.ui {
  &.dropdown {
    .search {
      font-size: $font-sm !important;
      line-height: 1.8 !important;
      font-family: $font-roboto;
      letter-spacing: $default-ls;
    }
  }
}

.pin-digit {
  padding: $sm !important;
}

.ui {
  &.input {
    &.addon {
      &>input {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
      }
    }
  }
}
.ui {
  &.dropdown {
    &>.clear {
      &.icon {
        &:before {
          display: inline-block;
          font: normal normal normal 24px/1 "Material Design Icons";
          font-size: inherit;
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\f156' !important;
        }
      }
    }
  }
}
[data-toggle="ui-isd-dropdown"] {
  min-width: 100px;
  padding: .51857143em 1em !important;
  min-height: 2.5em !important;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  .text {
    &.default {
      font-size: $font-sm;
    }
  }
}

[data-toggle="ui-addon-dropdown"] {
  min-width: 100px;
  padding: .51857143em 1em !important;
  min-height: 2.5em !important;
  border: 1px solid rgba(34,36,38,.15);
  border-radius: .28571429rem;
  border-right: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  .text {
    &.default {
      font-size: $font-sm;
    }
  }
}

#profile-form {
  .ui {
    &.checkbox {
      label{
        &:after {
            font-size: 11px !important;
            top: 1px !important;
            left: 1px !important;
        }
      }
    }
  }
  .ui {
    &.dropdown {
      .text {
        &.default {
          font-size: $font-sm !important;
        }
      }
      &.search, &.selection {
        padding: .67857143em 1em !important;
        min-height: 2.5em !important;
        &>.dropdown {
          &.icon {
            line-height: 1 !important;
            top: .85em;
            padding: 0.83em;
            &.clear {
              &:before {
                display: inline-block;
                font: normal normal normal 24px/1 "Material Design Icons";
                font-size: inherit;
                text-rendering: auto;
                line-height: inherit;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: '\f156' !important;
              }
            }
          }
        }
      }
    }
  }
  .ui {
    &.form {
      .field {
        &>label {
          font-size: $font-sm !important;
          font-family: $font-roboto;
          letter-spacing: $default-ls;
          margin-bottom: $sm;
          line-height: 1.4;
          margin-left: 1em;
          margin-right: 1em;
        }
        &[input-type="select"] {
          font-size: $font-sm !important;
          .item {
            font-size: $font-sm !important;
            font-family: $font-roboto;
            letter-spacing: $default-ls;
            line-height: 1.6;
          }
          .text {
            font-size: $font-sm !important;
            font-family: $font-roboto;
            letter-spacing: $default-ls;
            line-height: 1.6;
          }
        }
        .ui {
          &.input {
            &>input {
              line-height: 1.6;
              font-size: $font-sm !important;
            }
          }
        }
      }
    }
  }
}

#upload-form {
    .ui {
        &.checkbox {
            label{
                &:after {
                    font-size: 11px !important;
                    top: 1px !important;
                    left: 1px !important;
                }
            }
        }
    }
    .ui {
        &.dropdown {
            .text {
                &.default {
                    font-size: $font-sm !important;
                }
            }
            &.search, &.selection {
                padding: .67857143em 1em !important;
                min-height: 2.5em !important;
                &>.dropdown {
                    &.icon {
                        line-height: 1 !important;
                        top: .85em;
                        padding: 0.83em;
                        &.clear {
                            &:before {
                                display: inline-block;
                                font: normal normal normal 24px/1 "Material Design Icons";
                                font-size: inherit;
                                text-rendering: auto;
                                line-height: inherit;
                                -webkit-font-smoothing: antialiased;
                                -moz-osx-font-smoothing: grayscale;
                                content: '\f156' !important;
                            }
                        }
                    }
                }
            }
        }
    }
    .ui {
        &.form {
            .field {
                &>label {
                    font-size: $font-sm !important;
                    font-family: $font-roboto;
                    letter-spacing: $default-ls;
                    margin-bottom: $sm;
                    line-height: 1.4;
                    margin-left: 1em;
                    margin-right: 1em;
                }
                &[input-type="select"] {
                    font-size: $font-sm !important;
                    .item {
                        font-size: $font-sm !important;
                        font-family: $font-roboto;
                        letter-spacing: $default-ls;
                        line-height: 1.4;
                    }
                    .text {
                        font-size: $font-sm !important;
                        font-family: $font-roboto;
                        letter-spacing: $default-ls;
                        line-height: 1.4;
                    }
                }
                .ui {
                    &.input {
                        &>input {
                            line-height: 1.6;
                            font-size: $font-sm !important;
                        }
                    }
                }
            }
        }
    }
}

.ui {
  &.form {
    #twofa-form {
      .field {
        label {
          font-size: $font-sm !important;
          font-family: $font-roboto;
          letter-spacing: $default-ls;
          margin-bottom: $sm;
        }
      }
    }
    .field {
      label.required {
        &:after {
          content: '*';
          color: $material-color-red-500;
        }
      }
    }
  }
}

.confirmation-text {
  font-size: 0.88rem !important;
  font-family: $font-roboto;
  letter-spacing: $default-ls;
  margin-bottom: $sm;
}

.dtp {
  font-family: $font-roboto;
  .year-picker-item {
    &.active {
      color: $theme-color !important;
      font-weight: bold;
    }
    &:hover {
      color: $theme-color !important;
    }
  }
  .p10 {
    &>a {
      color: $white !important;
    }
  }
  table.dtp-picker-days {
    tr {
      &>td {
        &>a.selected {
          background: $material-color-blue-500 !important;
        }
      }
    }
  }
  &>.dtp-content {
    &>.dtp-date-view {
      &>header.dtp-header {
        background: $theme-color !important;
      }
      &>.dtp-date {
        background: $theme-color !important;
      }
    }
  }
}

.ui {
  &.steps {
    &>.step {
      &.completed {
        &:before {
          font: normal normal normal 24px/1 "Material Design Icons";
          text-rendering: auto;
          line-height: inherit;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\F12C' !important;
          display: block;
          position: static;
          text-align: center;
          -ms-flex-item-align: middle;
          align-self: middle;
          margin-right: 1rem;
          font-size: 2.5em;
          font-weight: 700;
          color: #21ba45;
        }
      }
    }
  }
  &.selection {
    &.dropdown {
      &>.dropdown {
        &.icon {
          z-index: 4 !important;
        }
      }
    }
  }
}

.btn:focus, .btn.focus {
    outline: 0;
    box-shadow: none;
}

.popover-body {
  font-size: $font-sm;
  font-family: $font-roboto;
  letter-spacing: $default-ls;
}

.dtp-actual-year {
    &:after {
        content: '\FA63';
        position: absolute;
        display: inline-block;
        font: normal normal normal 24px/1 "Material Design Icons";
        font-size: inherit;
        text-rendering: auto;
        line-height: inherit;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.ui {
    &.form {
        input {
            font-size: $font-sm;
            line-height: 1;
        }
    }
}

.document-footer {
    background-color: $section-inverse-color;
    h1 {
        color: $material-color-grey-500;
    }
    p {
        font-size: $font-regular;
        color: $material-color-grey-700;
        letter-spacing: $default-ls;
    }
}

.pmd-tabs {
    .nav-tabs {
        &>li {
            padding-bottom: 10px;
            font-family: $font-roboto;
            letter-spacing: $default-ls;
            text-align: left;
            &>a {
                padding-left: 0px !important;
                text-transform: none !important;
                &:hover {
                    text-decoration: none;
                }
            }
            &.active {
                &.active-primary {
                    &>a {
                        color: $material-color-blue-600;
                        &:hover, &:focus {
                            color: $material-color-blue-600;
                        }
                    }
                }
                &.active-warning {
                    &>a {
                        color: $material-color-yellow-800;
                        &:hover, &:focus {
                            color: $material-color-yellow-800;
                        }
                    }
                }
                &.active-success {
                    &>a {
                        color: $material-color-green-600;
                        &:hover, &:focus {
                            color: $material-color-green-600;
                        }
                    }
                }
                &.active-danger {
                    &>a {
                        color: $material-color-red-600;
                        &:hover, &:focus {
                            color: $material-color-red-600;
                        }
                    }
                }
            }
        }

    }
}

.pmd-card-body {
    padding: $md 0;
}
